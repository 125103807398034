import { XMarkIcon } from '@heroicons/react/24/solid'
import * as RadixToast from '@radix-ui/react-toast'
import { useLocation } from '@remix-run/react'
import classNames from 'classnames'
import type { ReactNode } from 'react'
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'

import type { Intent } from '~/services/theme-service'
import { getIntentSurface } from '~/services/theme-service'

interface ToastProviderProps {
  children: ReactNode
}

export interface ToastMessage {
  title: string
  description?: ReactNode
  duration?: number
  intent?: Intent
}

const noop = () => {}

const ToastContext = createContext<(toast: ToastMessage) => void>(noop)

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const { search } = useLocation()
  const [toast, setToast] = useState<ToastMessage | null>(null)
  const timerRef = useRef<number>(0)

  const addToast = useCallback((toast: ToastMessage) => {
    clearTimeout(timerRef.current)
    setToast(toast)
  }, [])

  useEffect(() => {
    if (typeof timerRef.current === 'number') {
      const timer = timerRef.current
      return () => clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(search)
    if (params.get('activated')) {
      addToast({
        intent: 'success',
        title: '🎉 Welcome to Cub Club!',
        description: 'Why not check out some of our featured videos?'
      })
    }
  }, [search, addToast])

  return (
    <RadixToast.Provider swipeDirection="right" duration={toast?.duration || 3000}>
      <ToastContext.Provider value={addToast}>{children}</ToastContext.Provider>
      <RadixToast.Root
        className={classNames('flex w-full flex-col rounded-md p-4 shadow-2xl', getIntentSurface(toast?.intent))}
        open={!!toast}
        onOpenChange={isOpen => {
          if (!isOpen) {
            setToast(null)
          }
        }}>
        <div className="flex items-center justify-between">
          <RadixToast.ToastTitle className="flex-1 text-lg font-bold">{toast?.title}</RadixToast.ToastTitle>
          <RadixToast.Close aria-label="Close">
            <XMarkIcon className="h-4 w-4" />
          </RadixToast.Close>
        </div>
        {toast?.description && (
          <RadixToast.ToastDescription className="mt-2 text-base">{toast.description}</RadixToast.ToastDescription>
        )}
      </RadixToast.Root>
      <RadixToast.Viewport className="flex-column fixed left-1/2 top-0 z-50 flex w-sm max-w-full -translate-x-1/2 transform gap-2 p-8" />
    </RadixToast.Provider>
  )
}

export const useToast = () => {
  return useContext(ToastContext)
}
